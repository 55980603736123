import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Couldn’t make it to SuperBot Conference, the ultimate chatbot and voice assistant conference? Watch the recordings of the discussions below.`}</p>
    <h3>{`Keynote by Arte Merritt, CEO of Dashbot`}</h3>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/Ma1hgWOmcJ0?feature=oembed" width="1200"></iframe>
    <h2>{`Panels`}</h2>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/OdQSi_ni7nI?feature=oembed" width="1200"></iframe>
    <h3>{`User Acquisition `}{`&`}{` Discovery`}</h3>
    <p>{`Speakers:`}<br parentName="p"></br>{`
`}{`Dmitry Dumik, Chatfuel`}<br parentName="p"></br>{`
`}{`Stefan Behren, Gyant`}<br parentName="p"></br>{`
`}{`Stefan Kojouharov, Chatbot’s Life`}<br parentName="p"></br>{`
`}{`Linda Lee, Facebook`}<br parentName="p"></br>{`
`}{`Khari Johnson, VentureBeat (Moderator)`}</p>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/HsWb17RDdRM?feature=oembed" width="1200"></iframe>
    <h3>{`Monetization Strategies`}</h3>
    <p>{`Speakers:`}<br parentName="p"></br>{`
`}{`David Cohen, eBay`}<br parentName="p"></br>{`
`}{`Lauren Kunze, Pandorabots`}<br parentName="p"></br>{`
`}{`Mark Wasiljew, Fandango`}<br parentName="p"></br>{`
`}{`Peter Buchroithner, Swell`}<br parentName="p"></br>{`
`}{`Ellen Huet, Bloomberg (Moderator)`}</p>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/_yRP2B4CnUk?feature=oembed" width="1200"></iframe>
    <h3>{`The Age of Voice`}</h3>
    <p>{`Speakers:`}<br parentName="p"></br>{`
`}{`Anamita Guha, IBM Watson — Developers Lab`}<br parentName="p"></br>{`
`}{`Kenton Langstroth, Intersection`}<br parentName="p"></br>{`
`}{`Max Koziolek, Spectrm`}<br parentName="p"></br>{`
`}{`Vera Tzoneva, Google`}<br parentName="p"></br>{`
`}{`Mario Tapia, MobileMonday (Moderator)`}</p>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/UoH-URtoKRQ?feature=oembed" width="1200"></iframe>
    <h3>{`Big Brand Bot Strategy`}</h3>
    <p>{`Speakers:`}<br parentName="p"></br>{`
`}{`Anamita Guha, IBM Watson — Developers Lab`}<br parentName="p"></br>{`
`}{`Kenton Langstroth, Intersection`}<br parentName="p"></br>{`
`}{`Max Koziolek, Spectrm`}<br parentName="p"></br>{`
`}{`Vera Tzoneva, Google`}<br parentName="p"></br>{`
`}{`Mario Tapia, MobileMonday (Moderator)`}</p>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/i7B-fYl_P4U?feature=oembed" width="1200"></iframe>
    <h3>{`Investor Insights `}{`&`}{` Funding Environment`}</h3>
    <p>{`Speakers:`}<br parentName="p"></br>{`
`}{`Alex Ferrara, Bessemer Venture Partners`}<br parentName="p"></br>{`
`}{`Courtney Nelson, BDMI`}<br parentName="p"></br>{`
`}{`John Rodkin, SamsungNEXT`}<br parentName="p"></br>{`
`}{`Ryan Armbrust, ffVC`}<br parentName="p"></br>{`
`}{`Jon Bruner, O’Reilly (Moderator)`}</p>
    <hr></hr>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`You can view detailed analytics `}{`&`}{` competitive market metrics, analyze conversation transcripts, track message funnels, optimize referral campaigns, understand sentiment `}{`&`}{` demographics, and add human-in-the-loop to your bot — all in real time.`}</p>
    <p>{`We natively support Facebook Messenger, Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      